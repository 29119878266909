import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import responseStatus from './common/redux/reducers.responseStatus'
import auth from './auth/redux/reducers'
import forgotPw from './auth/redux/reducers.forgotPw'
import home from './home/redux/reducers'
import profile from './profile/redux/reducers'
import publicProfile from './publicUser/redux/reducers'
import notifications from './common/redux/reducers.notifications'
import signup from './auth/redux/reducers.signup'
import campaign from './campaign/redux/reducers'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  auth,
  forgotPw,
  home,
  profile,
  notifications,
  signup,
  publicProfile,
  campaign,
})

export default rootReducer
