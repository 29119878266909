const styles = theme => ({
  mainTitle: {
    margin: '0 0 40px 0',
    textTransform: 'capitalize',
    fontWeight: '300',
    color: '#545454',
    fontSize: '36px',
    letterSpacing: 'normal',
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  },
  title: {
    fontSize: '24px',
    color: '#37474F',
    margin: 0,
    fontWeight: 500,
    marginTop: 5,
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
    display: 'inline-block',
  },
  subtitle: {
    textTransform: 'capitalize',
    fontWeight: 500,
    color: '#a1a1a1',
    fontSize: '17px',
    letterSpacing: 'normal',
    lineHeight: 1.97,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  itemTitle: {
    fontSize: '31px',
    opcity: 0.87,
    fontWeight: 500,
    color: '#6a6a6a',
    margin: 10,
    textAlign: 'center',
  },
  widgetTitle: {
    fontSize: '18px',
    fontWeight: 500,
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingBottom: '25px',
  },
  smallWidgetTitle: {
    fontSize: '18px',
    fontWeight: 500,
    paddingTop: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('1401')]: {
      paddingLeft: '10px',
    },
  },
  loginContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      minHeight: '400px',
      maxHeight: '500px',
      paddingTop: 30,
    },
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
    },
  },
  hintText: {
    color: '#37474F',
    fontSize: '16px',
    textAlign: 'center',
  },
  forgotButton: {
    fontWeight: 'normal',
    fontSize: '16px',
    borderRadius: '8px',
    '&:disabled': {
      backgroundColor: '#CFD8DC',
      color: '#90A4AE',
    },
    textTransform: 'inherit !important',
  },
  inputSignupAndLogin: {
    padding: '0',
    margin: '16px 0',
    '& input': {
      color: '#455A64',
      borderRadius: '8px',
    },
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  registrationPage: {
    display: 'flex',
    alignItems: 'center',
  },
  forgotPasswordForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
})

export default styles
