import imgLogo from '../../assets/logo.png'

export default class ImageUtils {
  static getAppLogoUrl() {
    return imgLogo
  }

  static convertBase64ToBlob(file) {
    try {
      const BASE64_MARKER = ';base64,'
      const parts = String(file).split(BASE64_MARKER)
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      return new Blob([uInt8Array], {type: contentType})
    } catch (error) {
      return null
    }
  }
}
