import * as Types from './actions.types'

export function getVideos() {
  return {type: Types.GET_VIDEOS}
}

export function getVideosSuccess(videos) {
  return {type: Types.GET_VIDEOS_SUCCESS, payload: videos}
}

export function getVideosFailure(error) {
  return {type: Types.GET_VIDEOS_FAILURE, error}
}
