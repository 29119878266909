import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useWakeLock} from 'react-screen-wake-lock'

import LucidHome from './LucidHome'
import VideoStep from './VideoStep'
import SurveyStep from './SurveyStep'
import LoadingModal from './LoadingModal'

import {tagManagerEvent, eventTypes} from '../campaign/tagManager'
import SurveyContext from './context/SurveyContext'
import {createError as createErrorAction} from '../common/redux/actions.notifications'
import {clearToken as clearTokenAction, lucidLogin as lucidLoginAction} from '../auth/redux/actions'
import UserUtils from '../utils/UserUtils'
import VideoApi from './api'
import useUpdateEffect from '../../hooks/useUpdateEffect'

const lucidSurveySteps = {
  home: 'home',
  video: 'video',
  survey: 'survey',
}

const lucidRedirectionStatuses = {
  complete: 'complete',
  overquota: 'overquota',
  terminated: 'terminated',
}

const SurveyFlow = ({location, router, route}) => {
  const {profile} = useSelector(state => state.profile)
  const {token, errors} = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const wakeLock = useWakeLock({})
  const {RID, setRID, setCampaignVideo} = useContext(SurveyContext)

  const [currentStep, setCurrentStep] = useState(lucidSurveySteps.home)
  const [showLeavingAlert, setShowLeavingAlert] = useState(true)
  const [isFetchingVideo, setIsFetchingVideo] = useState(true)
  const [hasError, setHasError] = useState(true)
  const [updatingRedirectionStatus, setUpdatingRedirectionStatus] = useState(false)

  const lucidParams = location.search.substring(1)

  const clearToken = () => dispatch(clearTokenAction())
  const lucidLogin = lucidParams => dispatch(lucidLoginAction(lucidParams))
  const createError = message => dispatch(createErrorAction(message))

  useEffect(() => {
    clearToken()
    lucidLogin(lucidParams)
    setRID(UserUtils.getRIDFromLucidParams(lucidParams))

    wakeLock.request()

    return () => {
      wakeLock.release()
    }
  }, [])

  const removeAlert = () => {
    setShowLeavingAlert(false)
  }

  // react router navigation prevention
  useEffect(() => {
    const removeListener = router.setRouteLeaveHook(route, () => {
      if (showLeavingAlert) {
        return 'Are you sure you want to leave? All your progress will be lost.'
      }
    })

    return removeListener
  }, [router, route, showLeavingAlert])

  // window unload prevention
  useEffect(() => {
    const alertUser = e => {
      if (showLeavingAlert) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', alertUser)

    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [showLeavingAlert])

  useUpdateEffect(() => {
    const getVideo = async () => {
      if (token) {
        try {
          setIsFetchingVideo(true)
          const fetchedCampaignVideo = await VideoApi.getVideoByCampaignId(
            UserUtils.getCampaignIdFromLucidParams(lucidParams),
          )
          setHasError(false)
          setCampaignVideo(fetchedCampaignVideo)
        } catch (error) {
          const message = error.response?.data?.error || error.message
          tagManagerEvent(eventTypes.fetchVideoError, {errorMessage: message, RID})
          createError(message)
          setHasError(true)
        }

        setIsFetchingVideo(false)
      }
    }

    getVideo()
  }, [token, lucidParams])

  const lucidTerminate = () => {
    removeAlert()

    setUpdatingRedirectionStatus(true)
    VideoApi.updateLucidRedirectionStatus(profile.id, lucidRedirectionStatuses.terminated).finally(
      () => {
        setUpdatingRedirectionStatus(false)
        console.log('Lucid|Terminate|' + profile.lucidRedirection.terminate)
        window.location.replace(profile.lucidRedirection.terminate)
      },
    )
  }

  const lucidOverquota = async () => {
    removeAlert()

    setUpdatingRedirectionStatus(true)
    await VideoApi.updateLucidRedirectionStatus(
      profile.id,
      lucidRedirectionStatuses.overquota,
    ).finally(() => {
      setUpdatingRedirectionStatus(false)
      console.log('Lucid|overquota|' + profile.lucidRedirection?.overquota)
      window.location.replace(profile.lucidRedirection?.overquota)
    })
  }

  const lucidComplete = async () => {
    removeAlert()

    setUpdatingRedirectionStatus(true)
    await VideoApi.updateLucidRedirectionStatus(
      profile.id,
      lucidRedirectionStatuses.complete,
    ).finally(() => {
      setUpdatingRedirectionStatus(false)
      console.log('Lucid|Completed|' + profile.lucidRedirection?.complete)
      tagManagerEvent(eventTypes.testCompleted, {RID})
      window.location.replace(profile.lucidRedirection?.complete)
    })
  }

  if (!profile) return null

  const getCurrentStep = () => {
    switch (currentStep) {
      case lucidSurveySteps.home:
        return (
          <LucidHome
            lucidRedirection={profile?.lucidRedirection}
            lucidParams={lucidParams}
            nextStep={() => setCurrentStep(lucidSurveySteps.video)}
            lucidTerminate={lucidTerminate}
            isFetchingVideo={isFetchingVideo && !errors.error}
            hasError={hasError || errors.error}
          />
        )
      case lucidSurveySteps.video:
        return (
          <VideoStep
            lucidRedirection={profile?.lucidRedirection}
            nextStep={() => setCurrentStep(lucidSurveySteps.survey)}
            lucidTerminate={lucidTerminate}
            lucidOverquota={lucidOverquota}
          />
        )
      case lucidSurveySteps.survey:
        return (
          <SurveyStep
            lucidRedirection={profile?.lucidRedirection}
            lucidTerminate={lucidTerminate}
            lucidOverquota={lucidOverquota}
            lucidComplete={lucidComplete}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <LoadingModal
        text="Please do not close your browser. We are processing your survey."
        open={updatingRedirectionStatus}
      />
      {getCurrentStep()}
    </>
  )
}

export default SurveyFlow
