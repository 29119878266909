import React from 'react'
import {SvgIcon} from '@material-ui/core'

import CampaignTesterLogo from '../../assets/campaigntester-logo.png'

const BaseSvg = ({height, width, children, ...props}) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    version="1.1"
    viewBox={`0 0 ${width} ${height}`}
    width={`${width}px`}
    height={`${height}px`}
    {...props}
  >
    {children}
  </SvgIcon>
)

const Logo = ({...props}) => <img src={CampaignTesterLogo} alt="CampaignTester Logo" {...props} />

const FourOFour = ({...props}) => (
  <BaseSvg {...props} width={1673} height={774}>
    <path d="M1088,862.2H983.2V330.5H658.8l-433,531.7l49.3,203.5h424.4v151.7h283.7v-151.7H1088V862.2z M509.5,862.2l190-236.7v236.7H509.5zM3103.3,862.2h-104.9V330.5H2674l-433,531.7l49.3,203.5h424.4v151.7h283.7v-151.7h104.9V862.2z M2524.7,862.2l190-236.7v236.7H2524.7zM2114.4,330.6h-882.7c-25.6,0-46.4,20.8-46.4,46.4v794.1c0,25.6,20.8,46.4,46.4,46.4h882.7c25.6,0,46.4-20.8,46.4-46.4V377.1C2160.8,351.4,2140,330.6,2114.4,330.6zM1457.4,419.5h611.7v44.7h-611.7V419.5z M1387.5,419.5c12.4,0,22.4,10,22.4,22.4s-10,22.4-22.4,22.4c-12.4,0-22.4-10-22.4-22.4S1375.2,419.5,1387.5,419.5zM1275.3,441.9c0-12.4,10-22.4,22.4-22.4c12.4,0,22.4,10,22.4,22.4s-10,22.4-22.4,22.4C1285.3,464.3,1275.3,454.3,1275.3,441.9zM2079.3,1106.6c0,13.8-11.2,24.9-24.9,24.9h-744.1c-13.8,0-24.9-11.2-24.9-24.9V579.7c0-13.8,11.2-24.9,24.9-24.9h744.1c13.8,0,24.9,11.2,24.9,24.9V1106.6zM1558.5,808.2l-45.2-45.2l-45.2,45.2l-30.3-30.3l45.2-45.2l-45.2-45.2l30.3-30.3l45.2,45.2l45.2-45.2l30.3,30.3l-45.2,45.2l45.2,45.2L1558.5,808.2zM1915.2,687.6l-45.2,45.2l45.2,45.2l-30.3,30.3l-45.2-45.2l-45.2,45.2l-30.3-30.3l45.2-45.2l-45.2-45.2l30.3-30.3l45.2,45.2l45.2-45.2L1915.2,687.6zM1530.2,920.4h125.1V942c0,32,8.2,57.5,24.7,76.4c16.5,18.9,38.8,28.3,67,28.3c26.3,0,48.1-8.8,65.4-26.5c10-10.1,16.8-21.8,20.3-35.1c3.6-13.2,5.4-32.6,5.4-58.4V874h-308V920.4zM1698.3,920.4h96.9v15.4c0,44-15.9,66-47.7,66c-32.8,0-49.3-21.3-49.3-64V920.4z" />
  </BaseSvg>
)

export {Logo, FourOFour}
