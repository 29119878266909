import {Grid, withStyles} from '@material-ui/core'

export const GridContainerPage = withStyles({
  item: {
    width: '100%',
    margin: 0,
  },
})(Grid)

export const GridItemPage = withStyles(theme => ({
  item: {
    marginLeft: '20',
    marginRight: '20',
    marginBottom: 0,
    maxWidth: '88%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))(Grid)
