import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Login from './auth/Login.jsx'
import Signup from './auth/Signup.jsx'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest.jsx'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import VideoList from './lucid/VideoList'
import SurveyFlow from './lucid/SurveyFlow'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      <IndexRoute component={VideoList} onEnter={authenticate} />
      <Route path="/lucid-login" component={SurveyFlow} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />

      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
