import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {Link} from 'react-router'
import {Card, CardContent, CircularProgress, Typography, Grid, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import queryString from 'query-string'
import settings from '../../../config/settings'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import Input from '../common/Input'
import Button from '../common/Button'
import PasswordInput from '../common/PasswordInput'
import * as Actions from './redux/actions'
// import {FacebookAuthButton} from './SocialAuthButtons.jsx' //TwitterAuthButton
import styles from './styles/AuthForm.styles'
// import pageUtil from '../utils/PageUtils'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      canSubmit: false,
    }

    this.popup = null

    this.changeUser = this.changeUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.login = this.login.bind(this)
    this.redirectIfAuthed = this.redirectIfAuthed.bind(this)
  }

  login(e) {
    e.preventDefault()

    const {isComponentValid, login} = this.props
    if (isComponentValid()) {
      login(this.state.username, this.state.password)
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    const id = parseInt(values.id, 10)
    const {token} = values
    // pageUtil.scrollToTop()
    if (this.props.location.search.includes('id' && 'token')) {
      this.props.autoLogin(id, token)
    }
    this.redirectIfAuthed(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.redirectIfAuthed(nextProps)
  }

  redirectIfAuthed(props) {
    const {location, token} = props
    if (token) {
      if (location.query.redirectTo) {
        this.context.router.push(location.query.redirectTo)
      } else {
        this.context.router.push('/')
      }
      return true
    }
    return false
  }

  render() {
    const {username, password, canSubmit} = this.state
    const {
      errors,
      //socialLoginOAuth1Step1,
      //socialLoginOAuth1Step2,
      // socialLoginOAuth2,
      // provider,
      //oauthToken,
      // isNoEmailProvidedError,
      //oauthTokenSecret,
      classes,
      isFetching,
    } = this.props

    return (
      <div className={classes.page}>
        <Helmet title="Log In" />
        <Card className={classes.cardCentered} elevation={0}>
          <CardContent className={classes.cardContent}>
            <Typography component="h4" variant="h4" className={classes.title} gutterBottom>
              Login
            </Typography>
            <form noValidate>
              <Grid container spacing={24}>
                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator errors={errors.email} shouldValidateOnBlur={true}>
                    <Input
                      type="email"
                      label="Email Address"
                      required
                      value={username}
                      onChange={this.changeUser}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator errors={errors.password} shouldValidateOnBlur={true}>
                    <PasswordInput
                      name="password"
                      label="Password *"
                      required
                      value={password}
                      onChange={this.changePassword}
                    />
                  </InputValidator>
                </Grid>

                <Grid Item xs={12} className={classes.infoField} style={{textAlign: 'left'}}>
                  <Link to="/forgot" className={classes.forgotPasswordLink}>
                    Forgot password?
                  </Link>
                </Grid>

                <Grid item xs={12} className={classes.submitButton}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.login}
                    disabled={!canSubmit}
                    style={{width: '110px'}}
                  >
                    {isFetching ? <CircularProgress color="surface.light" size={26} /> : 'Login'}
                  </Button>
                </Grid>

                {_.get(settings, 'website.socialLogins')
                  ? // <Grid item xs={12} className={classes.infoField}>
                    //   <Typography
                    //     component="h3"
                    //     variant="subtitle1"
                    //     className={classes.titleNoMar}
                    //     gutterBottom
                    //   >
                    //     Or log in with an existing account
                    //   </Typography>
                    //   <Grid container justify="center" spacing={16}>
                    //     <Grid item xs="auto" className={classes.title}>
                    //       <FacebookAuthButton
                    //         fbAppId={settings.fbAppId}
                    //         onSendAuthRequest={socialLoginOAuth2.bind(this)}
                    //         isNoEmailProvidedError={isNoEmailProvidedError && provider === 'facebook'}
                    //       />
                    //     </Grid>
                    //     {/* <Grid item xs="auto">
                    //       <TwitterAuthButton
                    //         oauthToken={oauthToken}
                    //         oauthTokenSecret={oauthTokenSecret}
                    //         onRetrieveAuthRequestToken={socialLoginOAuth1Step1.bind(this)}
                    //         onSendAuthRequest={socialLoginOAuth1Step2.bind(this)}
                    //         isNoEmailProvidedError={isNoEmailProvidedError && provider === 'twitter'}
                    //       />
                    //     </Grid> */}
                    //   </Grid>
                    //   {/*<GoogleplusAuthButton isNoEmailProvidedError={isNoEmailProvidedError && provider === 'googleplus'}/>
                    //   <LinkedinAuthButton isNoEmailProvidedError={isNoEmailProvidedError && provider === 'linkedin'}/>*/}
                    // </Grid>
                    null
                  : null}

                <Grid item xs={12}>
                  <Typography style={{fontSize: 16}}>
                    New User?{' '}
                    <Link to="/signup" className={classes.link}>
                      Create Account
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <div className="push" />
      </div>
    )
  }

  changeUser(event) {
    const username = event.target.value
    this.setState(prevState => {
      return {
        username,
        canSubmit: prevState.password && username,
      }
    })
  }

  changePassword(event) {
    const password = event.target.value
    this.setState(prevState => {
      return {
        password,
        canSubmit: prevState.username && password,
      }
    })
  }
}

Login.displayName = 'Login'

Login.propTypes = {}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.auth}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(Login)
