import axios from 'axios'
import customAxios from '../api/axios'
import settings from '../../../config/settings'

const {apiBaseURL, vevwApiKey, vevwApiURL} = settings

const vevwConfigs = {
  headers: {
    'X-Api-Key': vevwApiKey,
    'Content-Type': 'multipart/form-data',
  },
}

export default class VideoApi {
  static getVideos() {
    return customAxios.get(`${apiBaseURL}/campaign-videos`)
  }

  static startView(campaignVideoId) {
    return customAxios.post(`${apiBaseURL}/campaign-video-views`, {campaign_video: campaignVideoId})
  }

  static endView(videoViewId) {
    return customAxios.post(
      `${apiBaseURL}/campaign-video-views/${videoViewId}/end?expand=campaign_video.campaign`,
    )
  }

  static saveSurveyQuestionAnswer(campID, data) {
    return customAxios.post(`${apiBaseURL}/campaigns/${campID}/survey-answer`, data)
  }

  static captureImage(image, second, videoViewId) {
    const form = new FormData()
    form.append('image', image)
    form.append('second', second)
    form.append('recording_uid', videoViewId)
    form.append('recording_platform', 'Web')

    axios.create()
    return axios.post(`${vevwApiURL}/image-capture`, form, vevwConfigs)
  }

  static detectEngagement(image) {
    const form = new FormData()
    form.append('image', image)

    axios.create()
    return axios.post(
      `${vevwApiURL}/image-capture/detect-engage?use_aws_engage=0`,
      form,
      vevwConfigs,
    )
  }
}
