import React from 'react'
import {Grid, withStyles} from '@material-ui/core'
import SurveyMultipleChoiceButton from './SurveyMultipleChoiceButton'

const styles = theme => ({
  questionText: {
    fontSize: 24,
    color: '#4F4F4F',
    fontWeight: 400,
    margin: 20,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
})

const SurveyMultipleChoiceAnswer = ({
  classes,
  question,
  handleChoiceSelection,
  selectedChoice,
  setSelectedChoice,
}) => {
  return (
    <>
      <Grid className={classes.questionText}>{question.question}</Grid>
      <Grid style={{width: '100%'}}>
        {question.choices.map(choice => (
          <SurveyMultipleChoiceButton
            key={choice}
            buttonText={choice}
            handleChoiceSelection={handleChoiceSelection}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        ))}
      </Grid>
    </>
  )
}

export default withStyles(styles)(SurveyMultipleChoiceAnswer)
