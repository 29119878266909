const styles = theme => ({
  container: {
    background: 'black',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100vh',
    width: '100%',
    zIndex: '1101',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    maxHeight: '80%',
    pointerEvents: 'none',
  },
  videoHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'flex-end',
  },
  recordingContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem',
    background: 'rgb(0 0 0 / 60%)',
    borderRadius: '4px',
  },
  recordingMessage: {
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  timestamp: {
    color: 'white',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 500,
    marginLeft: 'auto',
    padding: '0.4rem',
    background: 'rgb(0 0 0 / 60%)',
    borderRadius: '4px',
  },
  test: {
    width: '200px',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  webcam: {
    display: 'block',
    position: 'absolute',
    right: '100%',
  },
})

export default styles
