import {call, put, all, takeEvery} from 'redux-saga/effects'
import VideoApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as AuthActions from '../../auth/redux/actions'

export default function* videoFlow() {
  yield all([takeEvery(Types.GET_VIDEOS, getVideos)])
}

function* getVideos() {
  try {
    const response = yield call(VideoApi.getVideos)
    yield put(Actions.getVideosSuccess(response))
  } catch (error) {
    yield put(Actions.getVideosFailure(error))
    if (error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}
