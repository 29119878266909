import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
// import {Grid} from '@material-ui/core'
import TagManager from 'react-gtm-module'

import Header from './common/Header'
import Footer from './common/Footer'
import NotificationContainer from './common/NotificationContainer'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage, GridItemPage} from './App.styles.js'

import settings from '../../config/settings.json'
import SurveyProvider from './lucid/context/SurveyProvider'

const headerlessLocations = ['confirm-email']
const footerlessLocations = ['confirm-email']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
    }
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)

    TagManager.initialize({
      gtmId: settings.googleTagManagerKey,
    })

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterState(nextProps.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/')[1]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter})
    }
  }

  render() {
    const {auth, profile, location} = this.props
    const {showHeader, showFooter} = this.state

    return (
      <GridContainerPage
        id="appContain"
        className="ba-site"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <CssBaseline />
        <Helmet
          defaultTitle={PageUtils.getTitle()}
          titleTemplate={`${PageUtils.getTitle()} - %s`}
          meta={[
            {name: 'twitter:card', content: 'summary'},
            {property: 'og:title', content: PageUtils.getTitle()},
            {property: 'og:site_name', content: PageUtils.getTitle()},
            {property: 'og:image', content: ImageUtils.getAppLogoUrl()},
            {
              property: 'og:description',
              content: 'CampaignTester',
            },
          ]}
        />
        {showHeader ? (
          <Header location={location} auth={auth} profile={profile} onHomeClick={() => {}} />
        ) : null}
        <SurveyProvider>
          <GridItemPage container justify="center" align="center" style={{flexGrow: 1}}>
            {this.props.children}
          </GridItemPage>
        </SurveyProvider>
        {showFooter ? <Footer /> : null}
        <NotificationContainer />
      </GridContainerPage>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    profile: _.get(state.profile, 'profile', null),
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)
