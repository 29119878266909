import TagManager from 'react-gtm-module'

export const eventTypes = {
  userProceeded: 'user_proceeded',
  userDeclined: 'user_declined',
  startVideo: 'start_video',
  terminateOnEyeContact: 'terminate_on_eye_contact',
  terminateOnViewItAll: 'terminate_on_view_it_all',
  terminateOnCameraError: 'terminate_on_camera_error',
  inactivityError: 'inactivity_error',
  networkError: 'network_error',
  videoEnded: 'video_ended',
  surveyAnswer: 'survey_answer',
  imageUploadModalStart: 'image_upload_modal_start',
  imageUploadModalEnd: 'image_upload_modal_end',
  surveyEnded: 'survey_ended',
  testCompleted: 'test_completed',
  imageUploadError: 'image_upload_error',
  overquota: 'overquota',
  videoEndUnexpectedError: 'video_end_unexpected_error',
  fetchVideoError: 'fetch_video_error',
  cameraSuccess: 'camera_success',
  cameraFailed: 'camera_failed',
  engagementDetected: 'engagement_detected',
  proceededOnEngagementDetected: 'proceeded_on_engagement_detected',
  engagementFailed: 'engagement_failed',
  proceededOnEyeContact: 'proceeded_on_eye_contact',
  proceededOnViewItAll: 'proceeded_on_view_it_all',
  videoPlayerError: 'video_player_error',
}

export const tagManagerEvent = (event, params = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...params,
    },
  })
}
