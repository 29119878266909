import {call, put, takeEvery, all} from 'redux-saga/effects'
import ProfileApi from '../../profile/api'
import * as Actions from './actions.forgotPw'
import * as Types from './actions.forgotPw.types'

export default function* forgotPasswordFlow() {
  yield all([
    takeEvery(Types.FORGOT_PW_REQUEST, forgotPasswordRequest),
    takeEvery(Types.FORGOT_PW_RESET, forgotPasswordConsume),
  ])
}

function* forgotPasswordRequest(action) {
  const {email, requestFrom} = action
  try {
    const response = yield call(ProfileApi.forgotPasswordRequest, email, requestFrom)
    yield put(Actions.requestForgotPwSuccess(response))
  } catch (error) {
    yield put(Actions.requestForgotPwFailure(error))
  }
}

function* forgotPasswordConsume(action) {
  const {token, newPassword} = action
  try {
    const response = yield call(ProfileApi.forgotPasswordConsume, token, newPassword)
    yield put(Actions.resetForgotPwSuccess(response))
  } catch (error) {
    yield put(Actions.resetForgotPwFailure(error))
  }
}
