import {fork, all} from 'redux-saga/effects'
import errorFlow from './common/redux/sagas.errors'
import authFlow from './auth/redux/sagas'
import forgotPasswordFlow from './auth/redux/sagas.forgotPw'
import homeFlow from './home/redux/sagas'
import profileFlow from './profile/redux/sagas'
import videoFlow from './campaign/redux/sagas'
import signupFlow from './auth/redux/sagas.signup'
import publicProfileFlow from './publicUser/redux/sagas'

export default function* root() {
  yield all([
    fork(errorFlow),
    fork(authFlow),
    fork(forgotPasswordFlow),
    fork(homeFlow),
    fork(profileFlow),
    fork(videoFlow),
    fork(signupFlow),
    fork(publicProfileFlow),
  ])
}
