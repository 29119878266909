export const CAPTURE_SNAPSHOT_INTERVAL = 1000 // miliseconds

export const DETECT_ENGAGEMENT_INTERVAL = 1000 // miliseconds

export const MAX_ENGAGEMENT_WAIT = 30 // seconds

export const ENGAGEMENT_SUCCESS_DELAY = 2000 // miliseconds

export const DETECT_CAMERA_DELAY = 500 // miliseconds

export const CAMERA_SUCCESS_DELAY = 1000 // miliseconds

export const MAX_INACTIVITY_TIME = 30 // seconds

export const MAX_BUFFERING_COUNT = 3 // times
