import React from 'react'

import {Box} from '@material-ui/core'
import {styled} from '@material-ui/styles'

import EmptyVideoList from './EmptyVideoList'

const VideoListContainer = styled(Box)(({theme}) => ({
  mainContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '95%',
    },
    overflow: 'hidden',
  },
  title: {
    textAlign: 'left',
    textTransform: 'initial',
    marginTop: 0,
    marginBottom: 16,
  },
}))

const VideoList = () => {
  return (
    <VideoListContainer>
      {/* always showing empty list in case lucid users somehow get to the index route */}
      <EmptyVideoList />
    </VideoListContainer>
  )
}

export default VideoList
