import {useRef, useEffect} from 'react'

const useComponentWillUnmount = (callback = () => {}) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    return () => callbackRef.current()
  }, [])
}

export default useComponentWillUnmount
