import {Typography} from '@material-ui/core'
import {styled} from '@material-ui/styles'

export const HomeTitle = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '1.6rem',
  marginBottom: '32px',
}))

export const HomeText = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '1.1rem',
  marginBottom: '32px',
}))

export const HomeLink = styled('a')(() => ({
  color: '#017eff',
}))
