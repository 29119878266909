import {useEffect, useState} from 'react'

const useWindowActivity = () => {
  const [isWindowActive, setIsWindowActive] = useState(true)

  function handleActivity(forcedFlag) {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setIsWindowActive(true) : setIsWindowActive(false)
    }

    return document.hidden ? setIsWindowActive(false) : setIsWindowActive(true)
  }

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false)
    const handleActivityTrue = () => handleActivity(true)

    document.addEventListener('visibilitychange', handleActivity)
    document.addEventListener('blur', handleActivityFalse)
    window.addEventListener('blur', handleActivityFalse)
    window.addEventListener('focus', handleActivityTrue)
    document.addEventListener('focus', handleActivityTrue)

    return () => {
      window.removeEventListener('blur', handleActivity)
      document.removeEventListener('blur', handleActivityFalse)
      window.removeEventListener('focus', handleActivityFalse)
      document.removeEventListener('focus', handleActivityTrue)
      document.removeEventListener('visibilitychange', handleActivityTrue)
    }
  }, [])

  return isWindowActive
}

export default useWindowActivity
