export const LOGIN = 'LOGIN'
export const AUTO_LOGIN = 'AUTO_LOGIN'
export const SOCIAL_LOGIN_OAUTH2 = 'SOCIAL_LOGIN_OAUTH2'
export const SOCIAL_LOGIN_OAUTH1_STEP1 = 'SOCIAL_LOGIN_OAUTH1_STEP1'
export const SOCIAL_LOGIN_OAUTH1_STEP2 = 'SOCIAL_LOGIN_OAUTH1_STEP2'
export const SOCIAL_LOGIN_OAUTH1_STEP1_SUCCESS = 'SOCIAL_LOGIN_OAUTH1_STEP1_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'
export const LUCID_LOGIN = 'LUCID_LOGIN'
export const LUCID_LOGIN_SUCCESS = 'LUCID_LOGIN_SUCCESS'
export const LUCID_LOGIN_FAILURE = 'LUCID_LOGIN_FAILURE'
