import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class ProfileApi {
  static getProfile() {
    return axios.get(`${apiBaseURL}/users/me`)
  }

  static updateProfile(id, update) {
    return axios.patch(`${apiBaseURL}/users/${id}`, update)
  }

  static createProfile(profile) {
    return axios.post(`${apiBaseURL}/register`, profile)
  }

  static forgotPasswordRequest(email, requestFrom) {
    return axios.post(`${apiBaseURL}/forgot-password`, {email, requestFrom})
  }

  static forgotPasswordConsume(token, newPassword) {
    return axios.post(`${apiBaseURL}/forgot-password/reset`, {
      token,
      newPassword,
    })
  }

  static changePassword(currentPassword, newPassword) {
    return axios.post(`${apiBaseURL}/users/change-password`, {
      currentPassword,
      newPassword,
    })
  }

  static changeEmailStep1Request(newEmail) {
    return axios.post(`${apiBaseURL}/change-email`, {newEmail})
  }

  static changeEmailStep2Confirm(id, token) {
    return axios.post(`${apiBaseURL}/change-email/${id}/confirm`, {token})
  }

  static changeEmailStep3Verify(id, token) {
    return axios.post(`${apiBaseURL}/change-email/${id}/verify`, {token})
  }

  static changeEmailResendConfirm() {
    return axios.post(`${apiBaseURL}/change-email/resend-confirm`)
  }

  static changeEmailResendVerify() {
    return axios.post(`${apiBaseURL}/change-email/resend-verify`)
  }

  static cancelChangeEmail() {
    return axios.post(`${apiBaseURL}/change-email/cancel`)
  }

  static verifyEmail(id, token) {
    return axios.post(`${apiBaseURL}/users/${id}/confirm-email`, {token})
  }
}
