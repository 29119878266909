const styles = theme => ({
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  title: {
    fontSize: '1.3rem',
    textAlign: 'center',
    margin: '0 0 8px 0',
  },
  titleNoMar: {
    textAlign: 'center',
  },
  card: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '440px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.shape,
    background: 'none',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: `${theme.spacing(6)} ${theme.spacing(6)}`,
    background: theme.palette.surface.lightBlue,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
    },
  },
  confirmButton: {
    marginTop: '20px',
  },
  infoField: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  linkNoVisitedColor: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
  link: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
  forgotPasswordLink: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary,
    },
  },
  cardCenteredLucidLogin: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.shape,
    background: 'none',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      borderRadius: '4px',
      '&:last-child': {
        marginLeft: theme.spacing(2),
      },
    },
  },
})

export default styles
