import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {styled} from '@material-ui/styles'

import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const Container = styled(Box)(() => ({
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.7)',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const PlayButton = styled(PlayArrowIcon)(() => ({
  fontSize: '300px',
  color: 'white',
  marginBottom: '20px',
}))

const Message = styled(Typography)(() => ({
  color: 'white',
  fontSize: '24px',
  textAlign: 'center',
  marginBottom: '20px',
}))

const Countdown = styled(Typography)(() => ({
  position: 'absolute',
  color: 'white',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontWeight: 500,
  padding: '0.4rem',
  background: 'rgb(0 0 0 / 60%)',
  borderRadius: '4px',
  top: '1.5rem',
  right: '1.5rem',
}))

const formatTime = time => {
  if (time < 0) return '0:00'
  return `0:${String(time).padStart(2, '0')}`
}

const PausedVideoOverlay = ({countdown}) => {
  return (
    <Container>
      <PlayButton />
      <Message>You must keep the screen active to continue watching the video.</Message>

      {/* this line assumes the countdown value is smaller than 60 */}
      <Countdown>{formatTime(countdown)}</Countdown>
    </Container>
  )
}

export default PausedVideoOverlay
