import React, {useRef, useState} from 'react'

import SurveyContext from './SurveyContext'
import VideoApi from '../api'
import {MAX_UPLOAD_RETRIES, RETRY_INTERVAL, UPLOAD_INTERVAL} from '../constants'

const SurveyProvider = ({children}) => {
  const [campaignVideo, setCampaignVideo] = useState({})
  const [RID, setRID] = useState('')
  const [imagesLoaded, setImagesLoaded] = useState(0)

  const capturesQueue = useRef([])
  const uploadRetries = useRef(0)

  const addImagesToUploadQueue = images => {
    capturesQueue.current.push(images)
  }

  const uploadImages = handleError => {
    if (
      imagesLoaded >= campaignVideo.video?.duration ||
      uploadRetries.current > MAX_UPLOAD_RETRIES
    ) {
      return
    }

    const imagesToUpload = capturesQueue.current.pop()

    if (imagesToUpload && imagesToUpload.length) {
      VideoApi.imageBulkUpload(imagesToUpload)
        .then(() => {
          setImagesLoaded(prevState => prevState + imagesToUpload.length)
        })
        .catch(error => {
          capturesQueue.current.push(imagesToUpload)
          uploadRetries.current += 1

          if (uploadRetries.current > MAX_UPLOAD_RETRIES) {
            handleError(error)
          } else {
            setTimeout(() => uploadImages(handleError), RETRY_INTERVAL)
          }
        })
    }

    setTimeout(() => uploadImages(handleError), UPLOAD_INTERVAL)
  }

  const startUploading = handleError => {
    capturesQueue.current = []
    uploadImages(handleError)
  }

  return (
    <SurveyContext.Provider
      value={{
        campaignVideo,
        setCampaignVideo,
        videoLength: campaignVideo.video?.duration || 0,
        imagesLoaded,
        addImagesToUploadQueue,
        startUploading,
        RID,
        setRID,
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}

export default SurveyProvider
