import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {Grid, withStyles, Button, Typography, Tooltip, CircularProgress} from '@material-ui/core'

import useModalState from '../../../hooks/useModalState'
import SurveyMultipleChoiceAnswer from './SurveyMultipleChoiceAnswer'
import SurveyOpenEndedAnswer from './SurveyOpenEndedAnswer'
import SurveyLanding from './SurveyLanding'
import {createError as createErrorAction} from '../../common/redux/actions.notifications'
import SurveyContext from '../context/SurveyContext'
import LoadingModal from '../LoadingModal'
import SurveyError from './SurveyError'
import {tagManagerEvent, eventTypes} from '../../campaign/tagManager'

import VideoApi from '../api'

const styles = theme => ({
  mainContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '25%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '70%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    textTransform: 'initial',
    marginTop: 0,
    marginBottom: 25,
    fontWeight: 700,
    fontSize: 44,
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  surveyQuestionCounter: {
    margin: 20,
    color: '#4F4F4F',
    fontWeight: 500,
  },
  nextButton: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 4,
    marginTop: '30px',
    padding: '10px 20px',
    background: '#017EFF',
    color: '#FFF',
    width: '100%',
    '&:hover': {
      backgroundColor: '#017EFF',
    },
    textTransform: 'initial',
  },
})

const SurveyStep = ({classes, lucidTerminate, lucidOverquota, lucidComplete}) => {
  const dispatch = useDispatch()

  const {imagesLoaded, videoLength, campaignVideo, RID} = useContext(SurveyContext)
  const [surveyStart, setSurveyStart] = useState(false)
  const [loadingAnswer, setLoadingAnswer] = useState(false)
  const [hasRedirected, setHasRedirected] = useState(false)
  const [surveyQuestions, setSurveyQuestions] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [totalSurveyQuestions, setTotalSurveyQuestions] = useState(0)
  const [answerCount, setAnswerCount] = useState(0)
  const [failedSurvey, setFailedSurvey] = useState(false)
  const [selectedChoice, setSelectedChoice] = useState('')
  const [loadingImagesModal, openLoadingImagesModal, closeLoadingImagesModal] = useModalState(false)

  const questionTypes = {multipleChoice: 0, openEnded: 1}

  const createError = message => dispatch(createErrorAction(message))

  useEffect(() => {
    if (
      imagesLoaded >= videoLength &&
      currentQuestionIndex === surveyQuestions.length - 1 &&
      loadingImagesModal
    ) {
      tagManagerEvent(eventTypes.imageUploadModalEnd, {
        RID,
        datetime: new Date().toJSON(),
      })
      closeLoadingImagesModal()
      handleNextButtonClick()
    }
  }, [imagesLoaded, videoLength])

  useEffect(() => {
    if (_.isEmpty(campaignVideo)) {
      setHasRedirected(true)
      lucidTerminate()
    } else if (campaignVideo.campaign.surveyQuestionsDetail) {
      setSurveyQuestions(campaignVideo.campaign.surveyQuestionsDetail)
      setTotalSurveyQuestions(campaignVideo.campaign.surveyQuestionsDetail.length)
    }
  }, [])

  const handleNextButtonClick = async () => {
    const currentQuestion = surveyQuestions[currentQuestionIndex]

    if (!window.navigator.onLine) {
      createError('No network connection')
      return
    }

    if (currentQuestionIndex === surveyQuestions.length - 1 && imagesLoaded < videoLength) {
      tagManagerEvent(eventTypes.imageUploadModalStart, {
        RID,
        datetime: new Date().toJSON(),
      })
      openLoadingImagesModal()
      return
    }

    if (selectedChoice) {
      try {
        setLoadingAnswer(true)
        await VideoApi.saveSurveyQuestionAnswer(campaignVideo.campaign.id, {
          question: currentQuestion.question,
          answer: selectedChoice,
        })
        tagManagerEvent(eventTypes.surveyAnswer, {
          RID,
          question: currentQuestion.question,
          answer: selectedChoice,
        })
        setAnswerCount(prevState => prevState + 1)
      } catch (error) {
        if (error.response.status === 406) {
          createError(
            "Participation for this test has reached it's quota. Your participation has been discarded.",
          )
          setHasRedirected(true)
          lucidOverquota()
        } else {
          const [message] = error?.response?.data?.['non_field_error'] || [error.message]

          // ignoring duplicated answer error
          if (message !== 'User already answered this question') {
            setFailedSurvey(true)
            createError(message)
          } else {
            setAnswerCount(prevState => prevState + 1)
          }
        }
      }
      setLoadingAnswer(false)
    }

    setSelectedChoice('')
    setCurrentQuestionIndex(prevState => prevState + 1)
  }

  const handleChoiceSelection = choice => {
    setSelectedChoice(choice)
  }

  const handleOpenEndedAnswer = e => {
    setSelectedChoice(e.target.value)
  }

  if (hasRedirected) return null

  if (
    !campaignVideo.campaign.surveyQuestionsDetail ||
    !campaignVideo.campaign.surveyQuestionsDetail.length
  ) {
    tagManagerEvent(eventTypes.surveyEnded, {RID})
    setHasRedirected(true)
    return lucidComplete()
  }

  const currentQuestion = surveyQuestions[currentQuestionIndex]

  if (!surveyStart) return <SurveyLanding setSurveyStart={setSurveyStart} />

  if (failedSurvey)
    return (
      <SurveyError
        lucidTerminate={() => {
          setHasRedirected(true)
          lucidTerminate()
        }}
      />
    )

  if (_.isEmpty(currentQuestion)) {
    if (surveyQuestions.length && answerCount === surveyQuestions.length) {
      tagManagerEvent(eventTypes.surveyEnded, {RID})
      setHasRedirected(true)
      return lucidComplete()
    }

    // handle error
    setFailedSurvey(true)
  }

  const getNextButtonText = () => {
    return currentQuestionIndex + 1 === totalSurveyQuestions ? 'Finish' : 'Next'
  }

  const getTooltipTitle = () => {
    if (!selectedChoice) {
      if (currentQuestion.type == questionTypes.openEnded) {
        return 'Please write your answer before going to the next question'
      }
      return 'Please select an answer from above before going to the next question.'
    }
    return ''
  }

  return (
    <Grid className={classes.mainContainer}>
      <LoadingModal
        text="Please wait while we process your results. This could take a few minutes."
        open={loadingImagesModal}
        progress
      />
      <Grid>
        <Typography variant="h5" className={classes.title}>
          Survey
        </Typography>
      </Grid>
      {currentQuestion.type == questionTypes.openEnded ? (
        <SurveyOpenEndedAnswer
          question={currentQuestion}
          handleOpenEndedAnswer={handleOpenEndedAnswer}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      ) : (
        <SurveyMultipleChoiceAnswer
          question={currentQuestion}
          handleChoiceSelection={handleChoiceSelection}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      )}
      <Grid style={{width: '100%'}}>
        <Tooltip disableInteractive title={getTooltipTitle()} placement="bottom">
          <span style={{display: 'block', height: '100%'}}>
            <Button
              disabled={!selectedChoice || loadingAnswer}
              className={classes.nextButton}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={handleNextButtonClick}
            >
              {loadingAnswer ? <CircularProgress size={24} /> : getNextButtonText()}
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Grid className={classes.surveyQuestionCounter}>
        Question {currentQuestionIndex + 1} of {totalSurveyQuestions}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SurveyStep)
