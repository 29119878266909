import React from 'react'
import {Typography, withStyles} from '@material-ui/core'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'

const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(1)}`,
  },
  icon: {
    fontSize: '120px',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    maxWidth: '60ch',
  },
})

const EmptyVideoList = ({classes}) => {
  return (
    <div className={classes.container}>
      <Typography variant="h4">There are no video tests available at this time.</Typography>
      <VideocamOffIcon className={classes.icon} />
      <Typography className={classes.description}>
        CampaignTester™ is a video focus group tool that measure audiences&apos; engagement and
        emotional reactions to videos.
        <br />
        <br />
        Companies that create and purchase the video tests will invite an audience to participate
        and schedule a start time and end time for the test to be completed. Once the tests expire,
        the video and the gift card offer are no longer valid.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(EmptyVideoList)
