import React from 'react'
import Helmet from 'react-helmet'
import {Typography, Grid, Button, withStyles, CircularProgress, TextField} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.forgotPw'
import styles from './styles/ForgotPassword.styles.js'
import checkCircle from '../../assets/checkCircle.svg'
import {requestFrom} from '../constants/RequestFrom.js'

class ForgotPasswordRequest extends React.Component {
  constructor() {
    super()

    this.state = {
      email: '',
    }
  }

  componentDidMount() {
    this.props.initForgotPw()
  }

  onChange(e) {
    this.setState({email: e.target.value})
  }

  onResetClick(e) {
    e.preventDefault()

    const {isComponentValid, requestForgotPw} = this.props
    if (isComponentValid()) {
      requestForgotPw(this.state.email, requestFrom.workerapp)
    }
  }

  renderForgotPassword(isSent, classes, errors, email, isFetching) {
    if (!isSent) {
      return (
        <>
          <Typography gutterBottom className={classes.title} style={{margin: 0}}>
            Forgot Password
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} style={{paddingBottom: '0'}}>
              <p className={classes.hintText}>
                To reset your password, please use the
                <br /> form below. We’ll send you an email to
                <br /> reset your password.
              </p>
            </Grid>
            <form onSubmit={this.onResetClick.bind(this)} className={classes.forgotPasswordForm}>
              <Grid item xs={12} style={{marginBottom: 16, width: '100%'}}>
                <InputValidator errors={errors.email}>
                  <TextField
                    id="outlined-email-input"
                    label="Email Address"
                    type="email"
                    name="email"
                    required
                    placeholder="Email Address"
                    autoComplete="email"
                    variant="outlined"
                    value={email}
                    onChange={e => this.setState({email: e.target.value})}
                    className={classes.inputSignupAndLogin}
                    margin="dense"
                    style={{height: 38}}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!this.state.email}
                  fullWidth
                  style={{width: '142px', height: '45px'}}
                  className={classes.forgotButton}
                >
                  {isFetching ? (
                    <CircularProgress size="24px" style={{color: '#F2F2F2'}} />
                  ) : (
                    'Send Link'
                  )}
                </Button>
              </Grid>
            </form>
          </Grid>
        </>
      )
    }
    return (
      <Grid container spacing={24}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <img src={checkCircle} alt="check circle" />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            padding: '0',
          }}
        >
          <Typography gutterBottom className={classes.title} style={{margin: 0}}>
            Email Sent!
          </Typography>
        </Grid>

        <Grid item xs={12} style={{paddingTop: '0'}}>
          <p className={classes.hintText}>
            Reset password email has been sent to <b>{email}</b>. Please follow the instructions
            <br /> from the email.
          </p>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {email} = this.state
    const {errors, classes, isSent} = this.props
    return (
      <div className={classes.registrationPage}>
        <Helmet title="Reset password" />
        <Grid container justify="center">
          <Grid
            item
            className={classes.loginContainer}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '440px',
            }}
          >
            <div
              style={{
                padding: '50px',
                borderRadius: '8px',
                border: '1px solid #E0E0E0',
                background: 'rgba(232, 243, 255, 0.2)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {this.renderForgotPassword(isSent, classes, errors, email)}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.forgotPw}
}

ForgotPasswordRequest.displayName = 'ForgotPasswordRequest'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(ForgotPasswordRequest)
