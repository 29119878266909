import reduceReducers from 'reduce-reducers'
import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function campaign(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_VIDEOS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaignVideos: action.payload,
      }
    case Types.GET_VIDEOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        campaignVideos: [],
        errors: ErrorUtils.getApiErrors(action.error),
      }
    default:
      return state
  }
}

function getInitialState() {
  return {
    isFetching: false,
    errors: {},
    campaignVideos: [],
  }
}

const reducer = reduceReducers((state, action) => campaign(state, action))

export default reducer
