import React from 'react'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/styles'
import {Paper, Typography} from '@material-ui/core'
// import {Link} from 'react-router'
// import _ from 'lodash'
// import {Typography, Paper, IconButton} from '@material-ui/core'
// import settings from '../../../config/settings'
// import {Logo} from './Icons.jsx'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    background: theme.palette.surface.light,
    textAlign: 'center',
    marginTop: 'auto',
    width: '100%',
  },
  linksContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  linkNoVisitedColor: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
  footerText: {
    fontSize: '12px',
  },
})

class Footer extends React.Component {
  backToTop(e) {
    e.preventDefault()

    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  showCurrentYear() {
    return new Date().getFullYear()
  }

  render() {
    const {classes} = this.props

    return (
      <footer>
        <Paper className={classes.paper} elevation={0}>
          <Grid container direction="row" justify="center">
            <Grid container justify="center" align="center">
              <Typography className={classes.footerText} style={{width: '30ch'}}>
                Copyright © {this.showCurrentYear()} CampaignTester™ All rights reserved.
              </Typography>
            </Grid>
            <Grid container justify="center" align="center" className={classes.linksContainer}>
              <a
                href="mailto:audience@campaigntester.com"
                className={`${classes.linkNoVisitedColor} ${classes.footerText}`}
              >
                Contact Us
              </a>
              <a
                href="https://campaigntester.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.linkNoVisitedColor} ${classes.footerText}`}
              >
                Terms & Conditions
              </a>
              <a
                href="https://campaigntester.com/privacy-policy/ "
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.linkNoVisitedColor} ${classes.footerText}`}
              >
                Privacy Policy
              </a>
            </Grid>
          </Grid>
        </Paper>
      </footer>
    )
  }
}

Footer.displayName = 'Footer'

export default withStyles(styles)(Footer)
