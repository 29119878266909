import React from 'react'
import {Box, CircularProgress, Dialog, Grid, withStyles} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

import Button from '../../common/Button'

import {processStatus} from '../../constants/Status'

import cameraPlaceholder from '../../../assets/camera_placeholder.png'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  previewContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
    background: 'black',
    width: '100%',
    position: 'relative',
  },
  previewFrame: {
    width: '50%',
    height: '80%',
    content: '""',
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: `linear-gradient(to right, #00b140 4px, transparent 4px) 0 0,
    linear-gradient(to right, #00b140 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #00b140 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #00b140 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #00b140 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #00b140 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #00b140 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #00b140 4px, transparent 4px) 100% 100%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40px 40px',
  },
  preview: {
    maxHeight: '40vh',
    width: 'auto',
    maxWidth: '100%',
  },
  statusText: {
    display: 'block',
    marginTop: theme.spacing(3),
    fontSize: '0.9rem',
    lineHeight: '1.2rem',
    maxWidth: '46ch',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    '& > strong': {
      display: 'block',
      marginBottom: theme.spacing(2),
    },
  },
  statusIcon: {
    fontSize: '3.2rem',
  },
  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: theme.palette.danger.main,
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  button: {
    borderRadius: 4,
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      flexGrow: 1,
    },
  },
  successButton: {
    marginRight: '0 !important',
  },
})

const EngagementStatusModal = props => {
  const {
    classes,
    open,
    engagementStatus,
    previewRef,
    redirectToHome,
    detectEngagement,
    startVideo,
  } = props

  const getEngagementStatus = () => {
    switch (engagementStatus) {
      case processStatus.success:
        return (
          <>
            <CheckCircleIcon
              className={`${classes.statusIcon} ${classes.success}`}
              fontSize="large"
            />
            <span className={classes.statusText}>Success!</span>
          </>
        )
      case processStatus.failure:
        return (
          <>
            <CancelRoundedIcon
              className={`${classes.statusIcon} ${classes.failure}`}
              fontSize="large"
            />
            <span className={classes.statusText}>
              <strong>Failed to detect engagement</strong>
              Please ensure you are within the frame and in a well-lit environment in order to
              continue.
            </span>
          </>
        )
      default:
        return (
          <>
            <CircularProgress size={64} />
            <span className={classes.statusText}>
              <strong>Detecting Engagement</strong>
              Please ensure you are within the frame and in a well-lit environment..
            </span>
          </>
        )
    }
  }

  return (
    <Dialog open={open} classes={{paper: classes.paper}}>
      <>
        <Grid className={classes.previewContainer}>
          <img
            ref={previewRef}
            src={cameraPlaceholder}
            alt="video-preview"
            className={classes.preview}
          />
          <Box className={classes.previewFrame} />
        </Grid>
        {getEngagementStatus()}
        {engagementStatus === processStatus.success && (
          <Grid className={classes.buttonsContainer}>
            <Button className={`${classes.button} ${classes.successButton}`} onClick={startVideo}>
              Start Video
            </Button>
          </Grid>
        )}
        {engagementStatus === processStatus.failure && (
          <Grid className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              onClick={redirectToHome}
              variant="outlined"
              color="secondary"
            >
              Exit Survey
            </Button>
            <Button className={classes.button} onClick={detectEngagement}>
              Try Again
            </Button>
          </Grid>
        )}
      </>
    </Dialog>
  )
}

export default withStyles(styles)(EngagementStatusModal)
