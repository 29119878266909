import React from 'react'
import {TextField, withStyles} from '@material-ui/core'

import styles from './styles/OutlinedInputStyles'

const Input = ({classes, ...rest}) => {
  return (
    <TextField
      variant="outlined"
      classes={{root: classes.root}}
      inputProps={{className: classes.input}}
      InputLabelProps={{className: classes.label}}
      {...rest}
    />
  )
}

export default withStyles(styles)(Input)
