import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles'
import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  IconButton,
  Divider,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import {Logo} from './Icons.jsx'
import Button from './Button'

import {logout} from '../auth/redux/actions'

const styles = theme => ({
  navbar: {
    padding: '10px 0',
    backgroundColor: theme.palette.surface.light,
  },
  toolbar: {
    width: '90%',
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
  userEmail: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  logout: {
    color: theme.palette.grey[800],
    textTransform: 'none',
    background: 'none',
    padding: '10px',
    '&:active': {
      background: 'none',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
    },
    '&:hover': {
      background: 'initial',
      opacity: '0.8',
    },
    '& span': {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  logoutLabel: {
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
    fontWeight: 400,
    lineHeight: 'initial',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  desktopNavItems: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileNavItems: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navbarTitle: {
    flex: 1,
  },
  navbarTitleLink: {
    color: 'white',
    fontSize: '32px',
  },
  navbarMenu: {
    marginLeft: 'auto',
    width: '40px',
    height: '40px',
    padding: 4,
    color: theme.palette.text.primary,
  },
  navbarDropdown: {
    zIndex: 1,
    minWidth: '12em',
  },
  brandImageContainer: {
    flex: 1,
  },
  brandImage: {
    height: 18,
    width: 'auto',
    filter: `drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2))`,
    [theme.breakpoints.down('xs')]: {
      height: 14,
    },
  },
  loginButton: {
    fontSize: '0.8rem',
  },
  registerButton: {
    marginLeft: '15px',
    fontSize: '0.6rem',
  },
  menuEmail: {
    marginTop: '0',
    marginBottom: '12px',
    minHeight: '0',
  },
  menuName: {
    marginBottom: '0',
    minHeight: '0',
  },
})

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      profileMenuOpened: false,
    }

    this.logout = this.logout.bind(this)
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this)
    this.renderHeaderDetails = this.renderHeaderDetails.bind(this)
  }

  componentWillMount() {
    this.setState({searchText: this.props.searchText})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.searchText !== nextProps.searchText) {
      this.setState({searchText: nextProps.searchText})
    }
  }

  handleProfileMenuOpen() {
    this.setState({profileMenuOpened: true})
  }

  handleProfileMenuClose() {
    this.setState({profileMenuOpened: false})
  }

  renderHeaderDetails() {
    const {classes, isFetching} = this.props
    const profile = _.get(this.props, 'profile')

    let profileEmail = ''
    let profileRegisteredFrom = ''

    if (profile) {
      profileEmail = profile.email
      profileRegisteredFrom = profile.registeredFrom
    }

    const location = _.get(this.props, 'location')
    const isLoggedIn = !!_.get(this.props, 'auth.token')
    const isLucidLogin = location.pathname.includes('/lucid-login')

    if (isLucidLogin || profileRegisteredFrom === 7) {
      return null
    }

    if (isLoggedIn) {
      return (
        <>
          <div className={classes.desktopNavItems}>
            <Typography className={classes.userEmail}>
              {isFetching ? <CircularProgress color="inherit" size={16} /> : profileEmail}
            </Typography>
            <Button
              className={classes.logout}
              onClick={e => {
                this.logout(e)
              }}
            >
              <span className="material-icons">logout</span>
              <span className={classes.logoutLabel}>Logout</span>
            </Button>
          </div>

          <div className={classes.mobileNavItems}>
            <IconButton
              buttonRef={n => {
                this.profileButtonRef = n
              }}
              onClick={this.handleProfileMenuOpen}
              className={classes.navbarMenu}
            >
              <MenuIcon className={classes.navbarMenu} />
            </IconButton>
            <Popper
              anchorEl={this.profileButtonRef}
              open={this.state.profileMenuOpened}
              transition
              disablePortal
              className={classes.navbarDropdown}
              placement="bottom-end"
            >
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                      <MenuList>
                        <MenuItem className={classes.menuEmail} button={false}>
                          <Typography className={classes.userEmail}>{profileEmail}</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={e => {
                            this.logout(e)
                          }}
                        >
                          <Button className={classes.logout} style={{padding: 0}}>
                            <span className="material-icons">logout</span>
                            <span className={classes.logoutLabel}>Logout</span>
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </>
      )
    }

    return (
      <>
        <div className={classes.desktopNavItems}>
          <Button component={Link} to="/login" variant="" className={classes.loginButton}>
            Login
          </Button>
          <Button className={classes.registerButton} component={Link} to="/signup">
            Create Account
          </Button>
        </div>

        <div className={classes.mobileNavItems}>
          <IconButton
            buttonRef={n => {
              this.profileButtonRef = n
            }}
            onClick={this.handleProfileMenuOpen}
            className={classes.navbarMenu}
          >
            <MenuIcon className={classes.navbarMenu} />
          </IconButton>
          <Popper
            anchorEl={this.profileButtonRef}
            open={this.state.profileMenuOpened}
            transition
            disablePortal
            className={classes.navbarDropdown}
            placement="bottom-end"
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                    <MenuList>
                      <MenuItem component={Link} to="/login">
                        Login
                      </MenuItem>
                      <Divider />
                      <MenuItem component={Link} to="/signup">
                        Create Account
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </>
    )
  }

  render() {
    const {props} = this
    const {classes} = props

    const location = _.get(props, 'location')
    const isLucidLogin = location.pathname.includes('/lucid-login')

    return (
      <AppBar color="default" className={classes.navbar} position="static" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.brandImageContainer}>
            {isLucidLogin ? (
              <Logo className={classes.brandImage} />
            ) : (
              <Link to="/">
                <Logo className={classes.brandImage} />
              </Link>
            )}
          </div>
          {this.renderHeaderDetails()}
        </Toolbar>
      </AppBar>
    )
  }

  logout() {
    this.props.dispatch(logout())
  }

  onSearch(keyEvent) {
    const key = keyEvent.which || keyEvent.keyCode

    if (key === 13) {
      const {searchText} = this.state

      const query = {q: searchText}
      this.context.router.push({pathname: '/', query})
    }
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {searchText: _.get(state, 'home.query.q', ''), isFetching: state.profile.isFetching}
}

export default connect(mapStateToProps)(withStyles(styles)(Header))
