import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Dialog, Typography, withStyles} from '@material-ui/core'

import Button from '../../common/Button'

const styles = theme => ({
  paper: {
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      borderRadius: '4px',
      '&:last-child': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  modalTitle: {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  modalText: {
    maxWidth: '32ch',
    fontSize: '0.8rem',
    margin: '20px 0',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      fontSize: '3rem',
      color: theme.palette.secondary.main,
    },
  },
})

const VideoConfirmationModal = props => {
  const {classes, icon, title, text, open, buttonText, onConfirm, onCancel, textProps} = props

  return (
    <Dialog open={open} classes={{paper: classes.paper}}>
      <>
        {icon && <Grid className={classes.iconContainer}>{icon}</Grid>}
        {title && <Typography className={classes.modalTitle}>{title}</Typography>}
        {text && (
          <Typography className={classes.modalText} {...textProps}>
            {text}
          </Typography>
        )}
        <Grid className={classes.buttonsContainer}>
          {onCancel && (
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              Exit Survey
            </Button>
          )}
          <Button onClick={onConfirm}>{buttonText}</Button>
        </Grid>
      </>
    </Dialog>
  )
}

VideoConfirmationModal.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

VideoConfirmationModal.defaultProps = {
  icon: null,
  title: null,
  text: null,
  onCancel: null,
}

export default withStyles(styles)(VideoConfirmationModal)
