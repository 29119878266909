import React from 'react'
import {Button as MuiButton, withStyles} from '@material-ui/core'

const styles = theme => ({
  button: {
    fontSize: '0.8rem',
    padding: `10px ${theme.spacing(4)}`,
    textTransform: 'initial',
    borderRadius: theme.spacing(1),
  },
})

const Button = ({classes, className, children, ...rest}) => {
  return (
    <MuiButton
      className={`${className || ''} ${classes.button}`}
      color="primary"
      variant="contained"
      {...rest}
    >
      {children}
    </MuiButton>
  )
}

export default withStyles(styles)(Button)
