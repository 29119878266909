import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import ReactPlayer from 'react-player'
import Webcam from 'react-webcam'
import {Grid, Typography, withStyles} from '@material-ui/core'
import {useStopwatch} from 'react-timer-hook'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import useUpdateEffect from '../../../hooks/useUpdateEffect'
import useModalState from '../../../hooks/useModalState'
import useFullscreen from '../../../hooks/useFullscreen'
import useComponentWillUnmount from '../../../hooks/useComponentWillUnmount'
import useWindowActivity from '../../../hooks/useWindowActivity'

import VideoConfirmationModal from './VideoConfirmationModal'
import LoadingModal from '../LoadingModal'
import EngagementStatusModal from './EngagementStatusModal'
import PausedVideoOverlay from './PausedVideoOverlay'

import ImageUtils from '../../utils/ImageUtils'
import {secondsToString} from '../../utils/TimeUtils'
import {processStatus} from '../../constants/Status'
import {
  DETECT_ENGAGEMENT_INTERVAL,
  MAX_ENGAGEMENT_WAIT,
  CAMERA_SUCCESS_DELAY,
  MAX_INACTIVITY_TIME,
  MAX_BUFFERING_COUNT,
} from '../../constants/VideoPlayer'
import {IMAGE_UPLOAD_BATCH_SIZE} from '../constants'

import VideoApi from '../api'

import {createError as createErrorAction} from '../../common/redux/actions.notifications'

import wifiOffIcon from '../../../assets/wifi-off.svg'

import styles from '../../campaign/styles/VideoPlayer.styles'
import {tagManagerEvent, eventTypes} from '../../campaign/tagManager'
import SurveyContext from '../context/SurveyContext'

const VideoStep = ({classes, nextStep, lucidTerminate, lucidOverquota}) => {
  const dispatch = useDispatch()

  const {campaignVideo, RID, addImagesToUploadQueue, startUploading} = useContext(SurveyContext)
  const playerRef = useRef(null)
  const webcamRef = useRef(null)
  const previewRef = useRef(null)
  const previewInterval = useRef(null)
  const capturesArray = useRef([])

  const isWindowActive = useWindowActivity()
  const {fullscreenRef, enterFullscreen, exitFullscreen} = useFullscreen()

  const inactiveTimeStopwatch = useStopwatch({})
  const engagementDetectionStopwatch = useStopwatch({})

  const [videoView, setVideoView] = useState(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [hasVideoEnded, setHasVideoEnded] = useState(false)
  const [videoDuration, setVideoDuration] = useState(0)
  const [videoCurrentTime, setVideoCurrentTime] = useState(0)
  const [bufferingCount, setBufferingCount] = useState(0)
  const [prevSecond, setPrevSecond] = useState(-1)
  const [showPausedVideoOverlay, setShowPausedVideoOverlay] = useState(false)

  const [isCameraActive, setIsCameraActive] = useState(false)
  const [cameraStatus, setCameraStatus] = useState(processStatus.unset)
  const [isCapturing, setIsCapturing] = useState(false)

  const [engagementStatus, setEngagementStatus] = useState(processStatus.unset)
  const [isDetectingEngagement, setIsDetectingEngagement] = useState(false)
  const [engagementAttempts, setEngagementAttempts] = useState(0)

  const [eyeContactModal, openEyeContactModal, closeEyeContactModal] = useModalState(false)
  const [viewItAllModal, openViewItAllModal, closeViewItAllModal] = useModalState(false)
  const [cameraFailedModal, openCameraFailedModal, closeCameraFailedModal] = useModalState(false)
  const [
    detectingEngagementModal,
    openDetectingEngagementModal,
    closeDetectingEngagementModal,
  ] = useModalState(false)
  const [detectingCameraModal, openDetectingCameraModal, closeDetectingCameraModal] = useModalState(
    false,
  )
  const [activityErrorModal, openActivityErrorModal, closeActivityErrorModal] = useModalState(false)
  const [
    slowNetworkErrorModal,
    openSlowNetworkErrorModal,
    closeSlowNetworkErrorModal,
  ] = useModalState(false)
  const [processingViewModal, openProcessingViewModal, closeProcessingViewModal] = useModalState(
    false,
  )

  const createError = message => dispatch(createErrorAction(message))

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    openEyeContactModal()
  }, [])

  useComponentWillUnmount(() => {
    if (previewInterval.current) {
      clearInterval(previewInterval.current)
    }
    stopCapturing()
  })

  useUpdateEffect(() => {
    switch (cameraStatus) {
      case processStatus.failure:
        closeDetectingCameraModal()
        openCameraFailedModal()
        break
      case processStatus.success:
        closeDetectingCameraModal()
        detectEngagement()
        break
      default:
        break
    }
  }, [cameraStatus])

  useEffect(() => {
    if (hasInactivityError()) {
      inactiveTimeStopwatch.pause()
      exitFullscreen()
      openActivityErrorModal()
    }
  }, [inactiveTimeStopwatch.seconds])

  useEffect(() => {
    if (hasReachedMaxBufferingCount()) {
      exitFullscreen()
      pauseVideo()
      openSlowNetworkErrorModal()
    }
  }, [bufferingCount])

  useEffect(() => {
    if (isCapturing) {
      if (!isWindowActive && !hasInactivityError()) {
        pauseVideo()
        setShowPausedVideoOverlay(true)
        // start timer
        inactiveTimeStopwatch.reset()
      } else {
        // play video
        if (!hasInactivityError()) {
          playVideo()
        }
        // stop timer
        inactiveTimeStopwatch.pause()
        setShowPausedVideoOverlay(false)
      }
    }
  }, [isWindowActive])

  const hasInactivityError = () => {
    return inactiveTimeStopwatch.seconds > MAX_INACTIVITY_TIME //seconds
  }

  const hasReachedMaxBufferingCount = () => {
    return bufferingCount > MAX_BUFFERING_COUNT
  }

  const hasReachedMaxEngagementWait = () => {
    return (
      engagementDetectionStopwatch.seconds + engagementDetectionStopwatch.minutes * 60 >
        MAX_ENGAGEMENT_WAIT || engagementAttempts > MAX_ENGAGEMENT_WAIT
    )
  }

  const handleUploadError = error => {
    tagManagerEvent(eventTypes.imageUploadError, {RID, errorMessage: error.message})
    createError(error.message)
    lucidTerminate()
  }

  const startCapturing = async () => {
    if (!isCapturing) {
      try {
        const view = await VideoApi.startView(campaignVideo.id)

        setVideoView(view)
        setIsCapturing(true)
        playVideo()
        startUploading(handleUploadError)
      } catch (error) {
        createError(error.message)
      }
    }
  }

  const stopCapturing = () => {
    setHasVideoEnded(true)
    exitFullscreen()
  }

  const getPreviewSnapshot = () => {
    previewInterval.current = setInterval(() => {
      if (webcamRef.current) {
        const image = webcamRef.current.getScreenshot()
        if (image) {
          previewRef.current.src = image
        }
      }
    }, 100)
  }

  const captureSnapshot = async (view, currentTime) => {
    try {
      const image = webcamRef.current.getScreenshot()
      const imageBlob = await ImageUtils.convertBase64ToBlob(image)

      if (imageBlob) {
        capturesArray.current.push({
          image: imageBlob,
          second: currentTime,
          recordingUid: view.uid,
        })

        if (capturesArray.current.length >= IMAGE_UPLOAD_BATCH_SIZE) {
          addImagesToUploadQueue(capturesArray.current)
          capturesArray.current = []
        }
      }
    } catch (error) {
      tagManagerEvent(eventTypes.imageUploadError, {RID, errorMessage: error.message})
      createError(error.message)
      lucidTerminate()
    }
  }

  const detectCamera = () => {
    openDetectingCameraModal()
    setIsCameraActive(true)
  }

  const stopEngagementDetection = () => {
    clearInterval(previewInterval.current)
    setIsDetectingEngagement(false)
    setEngagementAttempts(0)
    engagementDetectionStopwatch.pause()
  }

  useUpdateEffect(async () => {
    // engagement loop
    if (isDetectingEngagement) {
      if (engagementStatus === processStatus.unset) {
        if (hasReachedMaxEngagementWait()) {
          setEngagementStatus(processStatus.failure)
          stopEngagementDetection()
          tagManagerEvent(eventTypes.engagementFailed, {
            RID,
            errorMessage: 'Max engagement waiting time',
          })

          return null
        }

        try {
          const image = webcamRef.current.getScreenshot()
          const imageBlob = await ImageUtils.convertBase64ToBlob(image)

          if (imageBlob) {
            const response = await VideoApi.detectEngagement(imageBlob)
            if (response.data && response.data.engaged && !hasReachedMaxEngagementWait()) {
              // successfully detected engagement
              setEngagementStatus(processStatus.success)
              stopEngagementDetection()
              tagManagerEvent(eventTypes.engagementDetected, {RID})

              return null
            }
          }
        } catch (error) {
          tagManagerEvent(eventTypes.engagementFailed, {
            RID,
            errorMessage: error.message,
          })
          createError(error.message)
          stopEngagementDetection()
          lucidTerminate()

          return null
        }

        await new Promise(resolve => setTimeout(resolve, DETECT_ENGAGEMENT_INTERVAL))
        setEngagementAttempts(engagementAttempts + 1)
      }
    }
  }, [isDetectingEngagement, engagementAttempts])

  const detectEngagement = async () => {
    setEngagementStatus(processStatus.unset)
    getPreviewSnapshot()
    engagementDetectionStopwatch.reset()
    setIsDetectingEngagement(true)
    openDetectingEngagementModal()
  }

  const playVideo = () => {
    setIsVideoPlaying(true)
  }

  const pauseVideo = () => {
    setIsVideoPlaying(false)
  }

  const handleDuration = duration => {
    setVideoDuration(duration)
  }

  const handleProgress = progress => {
    if (!videoDuration) {
      return
    }

    const prevTime = videoCurrentTime
    const nextTime = videoDuration - progress.playedSeconds
    const playedSeconds = Math.floor(progress.playedSeconds)

    setVideoCurrentTime(nextTime)

    if (isVideoPlaying && !hasVideoEnded && prevTime !== nextTime && playedSeconds !== prevSecond) {
      setPrevSecond(playedSeconds)
      captureSnapshot(videoView, Math.floor(progress.playedSeconds))
    }
  }

  const handleVideoEnd = async () => {
    try {
      if (capturesArray.current.length) {
        addImagesToUploadQueue(capturesArray.current)
      }

      stopCapturing()
      openProcessingViewModal()
      tagManagerEvent(eventTypes.videoEnded, {RID})
      await VideoApi.endView(videoView.id)
      closeProcessingViewModal()
      nextStep()
    } catch (error) {
      if (error.response.status === 406) {
        createError(
          "Participation for this test has reached it's quota. Your participation has been discarded.",
        )
        tagManagerEvent(eventTypes.overquota, {RID})
        lucidOverquota()
      } else {
        tagManagerEvent(eventTypes.videoEndUnexpectedError, {RID, errorMessage: error.message})
        createError(error.message)
        lucidTerminate()
      }
    }
  }

  const renderModals = () => {
    return (
      <>
        <VideoConfirmationModal
          icon={<ErrorOutlineIcon />}
          text="Your session has timed out due to inactivity."
          open={activityErrorModal}
          buttonText="Exit Survey"
          onConfirm={() => {
            tagManagerEvent(eventTypes.inactivityError, {RID})
            closeActivityErrorModal()
            lucidTerminate()
          }}
          textProps={{
            style: {fontSize: '0.9rem'},
          }}
        />

        <VideoConfirmationModal
          icon={<img src={wifiOffIcon} alt="wifi off" />}
          text="Your internet connection is unstable! Please try again later."
          open={slowNetworkErrorModal}
          buttonText="Exit Survey"
          onConfirm={() => {
            tagManagerEvent(eventTypes.inactivityError, {RID})
            closeSlowNetworkErrorModal()
            lucidTerminate()
          }}
          textProps={{
            style: {fontSize: '0.9rem'},
          }}
        />

        <VideoConfirmationModal
          title="Eye Contact"
          text="Ensure you are facing the screen and your face is well lit."
          open={eyeContactModal}
          buttonText="Next"
          onConfirm={() => {
            tagManagerEvent(eventTypes.proceededOnEyeContact, {RID})
            closeEyeContactModal()
            openViewItAllModal()
          }}
          onCancel={() => {
            tagManagerEvent(eventTypes.terminateOnEyeContact, {RID})
            lucidTerminate()
          }}
        />

        <VideoConfirmationModal
          title="View It All"
          text="Complete the entire video to earn your reward."
          open={viewItAllModal}
          buttonText="Next"
          onConfirm={() => {
            tagManagerEvent(eventTypes.proceededOnViewItAll, {RID})
            closeViewItAllModal()
            detectCamera()
          }}
          onCancel={() => {
            tagManagerEvent(eventTypes.terminateOnViewItAll, {RID})
            lucidTerminate()
          }}
        />

        <LoadingModal text="Calibrating Camera" open={detectingCameraModal} />

        <VideoConfirmationModal
          title="Oops!"
          text={
            <span>
              It seems you didn&apos;t grant us permission to use your camera. Or your camera is not
              available.
              <br />
              <br />
              Please go and grant permissions in your settings and click Try Again.
            </span>
          }
          buttonText="Try Again"
          open={cameraFailedModal}
          onConfirm={() => {
            closeCameraFailedModal()
            openDetectingCameraModal()
            setIsCameraActive(true)
            setCameraStatus(processStatus.unset)
          }}
          onCancel={() => {
            tagManagerEvent(eventTypes.terminateOnCameraError, {RID})
            lucidTerminate()
          }}
        />

        <EngagementStatusModal
          open={detectingEngagementModal}
          previewRef={previewRef}
          engagementStatus={engagementStatus}
          lucidTerminate={lucidTerminate}
          detectEngagement={detectEngagement}
          startVideo={() => {
            tagManagerEvent(eventTypes.proceededOnEngagementDetected, {RID})
            closeDetectingEngagementModal()
            enterFullscreen()
            startCapturing()
            clearInterval(previewInterval.current)
          }}
        />

        <LoadingModal
          text="Processing"
          open={processingViewModal}
          paperProps={{style: {paddingLeft: 40, paddingRight: 40}}}
        />
      </>
    )
  }

  return (
    <Grid className={classes.container} ref={fullscreenRef}>
      {showPausedVideoOverlay ? (
        <PausedVideoOverlay countdown={MAX_INACTIVITY_TIME - inactiveTimeStopwatch.seconds} />
      ) : null}

      <Grid className={classes.videoHeader}>
        <div className={classes.recordingContainer} style={isCameraActive ? {} : {opacity: 0}}>
          <FiberManualRecordIcon fontSize="small" color="secondary" />
          <Typography className={classes.recordingMessage}>Recording</Typography>
        </div>
        {showPausedVideoOverlay ? null : (
          <Typography className={classes.timestamp}>{secondsToString(videoCurrentTime)}</Typography>
        )}
      </Grid>

      {renderModals()}

      <ReactPlayer
        className={classes.video}
        ref={ref => {
          playerRef.current = ref
        }}
        url={campaignVideo ? campaignVideo.video.videoFile : ''}
        playing={isVideoPlaying}
        onStart={() => tagManagerEvent(eventTypes.startVideo, {RID})}
        onDuration={handleDuration}
        progressInterval={500}
        onProgress={handleProgress}
        onEnded={handleVideoEnd}
        onBuffer={() => {
          // count the number of times we get here
          setBufferingCount(prevState => prevState + 1)
        }}
        onError={error => tagManagerEvent(eventTypes.videoPlayerError, {errorMessage: error, RID})}
        playsinline={true}
        controls={false}
      />

      {!hasVideoEnded && isCameraActive && (
        <Webcam
          className={classes.webcam}
          ref={webcamRef}
          muted={false}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          videoConstraints={{
            facingMode: 'user',
          }}
          onUserMedia={() => {
            tagManagerEvent(eventTypes.cameraSuccess, {RID})
            setTimeout(() => {
              setCameraStatus(processStatus.success)
            }, CAMERA_SUCCESS_DELAY)
          }}
          onUserMediaError={error => {
            tagManagerEvent(eventTypes.cameraFailed, {errorMessage: error, RID})
            setCameraStatus(processStatus.failure)
            setIsCameraActive(false)
          }}
        />
      )}
    </Grid>
  )
}

export default withStyles(styles)(VideoStep)
