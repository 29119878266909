import React, {useCallback, useContext} from 'react'
import {Card, CardContent, CircularProgress, Grid, withStyles} from '@material-ui/core'

import Button from '../../common/Button'

import {tagManagerEvent, eventTypes} from '../../campaign/tagManager'
import SurveyContext from '../context/SurveyContext'

import styles from '../../auth/styles/AuthForm.styles'
import {HomeTitle, HomeText, HomeLink} from './styled'

const LucidHome = ({classes, isFetchingVideo, nextStep, lucidTerminate, hasError}) => {
  const {RID} = useContext(SurveyContext)

  const handleDeclineClick = useCallback(() => {
    if (global.confirm('Are you sure?')) {
      tagManagerEvent(eventTypes.userDeclined, {RID})
      lucidTerminate()
    }
  }, [RID])

  const handleProceedClick = useCallback(() => {
    nextStep()
    tagManagerEvent(eventTypes.userProceeded, {RID})
  }, [RID])

  return (
    <div className={classes.page}>
      <Card className={classes.cardCenteredLucidLogin} elevation={0}>
        <CardContent className={classes.cardContent}>
          <HomeTitle variant="h4">We request your camera to be on.</HomeTitle>
          <HomeText>
            {'We '}
            <b>will not</b>
            {' be capturing your personal information that can be used to contact you later.'}
          </HomeText>
          <HomeText>
            Click <b>Continue</b> to accept our{' '}
            <HomeLink
              href="https://campaigntester.com/non-disclosure"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms
            </HomeLink>
            {' and begin the survey.'}
          </HomeText>
          <Grid className={classes.buttonsContainer}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleDeclineClick}
              disabled={isFetchingVideo || hasError}
            >
              Decline Reward
            </Button>
            <Button onClick={handleProceedClick} disabled={isFetchingVideo || hasError}>
              {isFetchingVideo ? (
                <CircularProgress size={26} style={{color: '#fff'}} />
              ) : (
                'Continue with Survey'
              )}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

LucidHome.propTypes = {}

export default withStyles(styles)(LucidHome)
