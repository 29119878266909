import axios from 'axios'
import customAxios from '../api/axios'
import settings from '../../../config/settings'

const {apiBaseURL, vevwApiKey, vevwApiURL} = settings

const vevwAxiosInstance = axios.create({
  baseURL: vevwApiURL,
  headers: {
    'X-Api-Key': vevwApiKey,
    'Content-Type': 'multipart/form-data',
  },
})

export default class VideoApi {
  static getVideos() {
    return customAxios.get(`${apiBaseURL}/campaign-videos`)
  }

  static getVideoByCampaignId(id) {
    return customAxios.get(`${apiBaseURL}/campaigns/${id}/video`)
  }

  static startView(campaignVideoId) {
    return customAxios.post(`${apiBaseURL}/campaign-video-views`, {campaign_video: campaignVideoId})
  }

  static endView(videoViewId) {
    return customAxios.post(
      `${apiBaseURL}/campaign-video-views/${videoViewId}/end?expand=campaign_video.campaign`,
    )
  }

  static saveSurveyQuestionAnswer(campID, data) {
    return customAxios.post(`${apiBaseURL}/campaigns/${campID}/survey-answer`, data)
  }

  static captureImage(image, second, videoViewId) {
    const form = new FormData()
    form.append('image', image)
    form.append('second', second)
    form.append('recording_uid', videoViewId)
    form.append('recording_platform', 'Web')

    return vevwAxiosInstance.post('/image-capture', form)
  }

  static imageBulkUpload(capturesArray) {
    const form = new FormData()

    capturesArray.forEach((capture, index) => {
      form.append('image' + index, capture.image)
      form.append('second' + index, capture.second)
      form.append('recording_uid' + index, capture.recordingUid)
      form.append('recording_platform' + index, 'Web')
    })

    form.append('total_images', capturesArray.length)

    return vevwAxiosInstance.post('/image-capture/bulk-upload', form)
  }

  static detectEngagement(image) {
    const form = new FormData()
    form.append('image', image)

    return vevwAxiosInstance.post('/image-capture/detect-engage?use_aws_engage=0', form)
  }

  static updateLucidRedirectionStatus(userId, redirectionStatus) {
    return customAxios.patch(`${apiBaseURL}/users/${userId}/update-lucid-redirection-status`, {
      redirectionStatus,
    })
  }
}
