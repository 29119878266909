import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Box, CircularProgress, Dialog, Typography, withStyles} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SurveyContext from './context/SurveyContext'

const styles = theme => ({
  paper: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: '0.9rem',
    marginTop: '20px',
    maxWidth: '300px',
    wordWrap: 'break-word',
  },
})

const LoadingModal = ({classes, text, open, success, paperProps, progress}) => {
  const {imagesLoaded, videoLength} = useContext(SurveyContext)

  return (
    <Dialog open={open} classes={{paper: classes.paper}} PaperProps={{...paperProps}}>
      <>
        {success ? (
          <CheckCircleIcon size={64} />
        ) : (
          <Box position="relative" display="inline-flex">
            <CircularProgress size={64} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {progress ? (
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                  (imagesLoaded / videoLength) * 100,
                )}%`}</Typography>
              ) : null}
            </Box>
          </Box>
        )}
        <Typography className={classes.text}>{success ? 'Success' : text}</Typography>
      </>
    </Dialog>
  )
}

LoadingModal.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  paperProps: PropTypes.objectOf(PropTypes.any),
}

LoadingModal.defaultProps = {
  success: false,
  paperProps: {},
}

export default withStyles(styles)(LoadingModal)
