const styles = theme => ({
  root: {
    '& fieldset': {
      borderRadius: `${theme.shape}px`,
    },
  },
  input: {
    fontSize: 16,
  },
  label: {
    fontSize: 16,
  },
})

export default styles
