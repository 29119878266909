import React from 'react'
import {Grid, withStyles, Button, Typography} from '@material-ui/core'

const styles = theme => ({
  mainContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '25%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '70%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    textTransform: 'initial',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 700,
    fontSize: 44,
    color: '#333333',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  pageContent: {
    fontWeight: 400,
    fontSize: 24,
    color: '#4F4F4F',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    margin: '52px 0',
  },
  finishButton: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 4,
    padding: '10px 20px',
    width: '100%',
    background: '#017EFF',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#017EFF',
    },
    textTransform: 'initial',
  },
})

const SurveyError = ({classes, lucidTerminate}) => {
  return (
    <Grid className={classes.mainContainer}>
      <Typography variant="h5" className={classes.title}>
        Oops!
      </Typography>
      <Grid className={classes.pageContent}>
        <span>
          Thank you for participating. Unfortunately something went wrong, please try again later.
        </span>
      </Grid>
      <Grid style={{width: '100%'}}>
        <Button className={classes.finishButton} onClick={lucidTerminate}>
          Finish
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SurveyError)
