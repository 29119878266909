import React from 'react'
import {Grid, withStyles, Button, Typography} from '@material-ui/core'

const styles = theme => ({
  mainContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '25%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '70%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    textTransform: 'initial',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 700,
    fontSize: 44,
    color: '#333333',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  pageContent: {
    fontWeight: 400,
    fontSize: 24,
    color: '#4F4F4F',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    margin: '52px 0',
  },
  nextButton: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 4,
    padding: '10px 20px',
    width: '100%',
    background: '#017EFF',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#017EFF',
    },
    textTransform: 'initial',
  },
})

const SurveyLanding = ({classes, setSurveyStart}) => {
  return (
    <Grid className={classes.mainContainer}>
      <Typography variant="h5" className={classes.title}>
        Thank You!
      </Typography>
      <Grid className={classes.pageContent}>
        Please take the following survey in order to receive your reward.
      </Grid>
      <Grid style={{fontWeight: 400, fontSize: 18, marginBottom: 8, width: '100%'}}>
        You will not receive rewards unless you complete the survey!
      </Grid>
      <Grid style={{width: '100%'}}>
        <Button className={classes.nextButton} onClick={() => setSurveyStart(true)}>
          Continue
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SurveyLanding)
