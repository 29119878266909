import React, {useState} from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  withStyles,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import styles from './styles/OutlinedInputStyles'

const PasswordInput = ({classes, label, ...rest}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password" className={classes.label}>
        {label}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        classes={{root: classes.root, input: classes.input}}
        required
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
              disableRipple={false}
            >
              {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={Math.floor(label.length * 8 + 10)}
        {...rest}
      />
    </FormControl>
  )
}

export default withStyles(styles)(PasswordInput)
