import React from 'react'
import {Grid, TextField, withStyles} from '@material-ui/core'

const styles = theme => ({
  questionText: {
    fontSize: 24,
    color: '#4F4F4F',
    fontWeight: 400,
    margin: 20,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
})

const SurveyOpenEndedAnswer = ({classes, question, selectedChoice, setSelectedChoice}) => {
  return (
    <>
      <Grid className={classes.questionText}>{question.question}</Grid>
      <Grid style={{width: '100%'}}>
        <TextField
          placeholder="Write your answer here."
          multiline
          margin="normal"
          variant="outlined"
          style={{display: 'flex', width: '100%'}}
          rows={5}
          value={selectedChoice}
          onChange={e => setSelectedChoice(e.target.value)}
        />
      </Grid>
    </>
  )
}

export default withStyles(styles)(SurveyOpenEndedAnswer)
