import React from 'react'
import {Button, withStyles} from '@material-ui/core'

const styles = theme => ({
  multipleChoiceButton: {
    borderRadius: 4,
    margin: '5px 0',
    width: '100%',
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: 500,
    background: '#CFE7FF',
    '&:hover': {
      backgroundColor: '#b3d6f8',
    },
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  multipleChoiceButtonActive: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 4,
    margin: '5px 0',
    padding: '10px 20px',
    width: '100%',
    background: '#4CAF50',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#4CAF50',
    },
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
})

const SurveyMultipleChoiceButton = ({
  classes,
  buttonText,
  handleChoiceSelection,
  selectedChoice,
  setSelectedChoice,
}) => {
  return (
    <Button
      className={
        buttonText === selectedChoice
          ? classes.multipleChoiceButtonActive
          : classes.multipleChoiceButton
      }
      onClick={() => {
        handleChoiceSelection(buttonText)
        setSelectedChoice(buttonText)
      }}
    >
      {buttonText}
    </Button>
  )
}

export default withStyles(styles)(SurveyMultipleChoiceButton)
